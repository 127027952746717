@import "Themes/constants.scss";

.root {
	font-size: 12px;
	color: var(--font-primary);

	.title {
		padding-top: 128px;
		padding-bottom: 40px;
		font-weight: 500;
		font-size: 30px;
		color: $color-black;
	}

	.form {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;

		> * {
			margin-bottom: 24px;
		}

		.hidden-input {
			margin-bottom: 40px;
		}

		.fields {
			width: 100%;
		}

		.link-register {
			font-weight: 400;
			font-size: 16px;
			letter-spacing: 0.5px;
			border-bottom: 1px solid var(--color-button-secondary);
			color: var(--color-button-secondary);
		}

		.link-forgot {
			font-weight: 400;
			font-size: 16px;
			letter-spacing: 0.5px;
			border-bottom: 1px solid $color-black;
			color: $color-black;
			cursor: pointer;
		}

		.button-container {
			display: flex;
			gap: 32px;
			flex-wrap: wrap;
			align-items: center;
		}
	}
}

.divider {
	width: 100%;
	height: 1px;

	margin: 24px 0;

	background-color: var(--color-neutral-4);
}

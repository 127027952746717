@import "Themes/constants.scss";

.root {
	position: fixed;
	padding: 16px;
	right: 0;
	top: 70px;

	@media (min-width: 1200px) {
		top: 130px;
	}

	z-index: 10;
	display: flex;
	flex-direction: column;

	& > *:not(:first-child) {
		margin-top: 16px;
	}

	overflow-y: auto;
	overflow-x: hidden;

	width: 443px;
	max-height: calc(100vh - 80px);
	@media (max-width: 444px) {
		width: unset;
		left: 0;
		padding: 8px;
	}
}

@import "Themes/constants.scss";

.root {
	position: relative;

	.input {
		padding: 24px;
		width: 100%;
		height: 70px;
		font-weight: 400;
		font-size: 18px;
		outline: none;
		background-color: $color-white;
		color: $color-black;
		border: 1px solid $color-grey-soft;
	}

	.error {
		color: $color-error;
		border-color: $color-error;
	}

	.error-text {
		color: $color-error;
	}

	.placeholder {
		pointer-events: none;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: calc(24px - 16px);
		padding: 0 16px;
		font-weight: 400;
		font-size: 16px;
		letter-spacing: 0.5px;
		background-color: $color-white;
		color: $color-black-transparent;
		cursor: text;
		transition: all 0.1s linear;
	}

	.focused {
		top: 0;
		z-index: 2;
	}
}

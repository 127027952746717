@import "Themes/constants.scss";

.root {
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-black-transparent;
	}

	.container {
		position: relative;
		padding: 60px 40px;
		width: 610px;
		background: $color-white;

		.cross {
			position: absolute;
			top: 32px;
			right: 32px;
			cursor: pointer;
		}

		.sub-container {
			h2 {
				margin-bottom: 24px;
			}
		}
	}
}

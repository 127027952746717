@import "Themes/constants.scss";

.root {
	border: none;
	cursor: pointer;
	transition: background-color 0.1s linear;

	&:disabled {
		cursor: not-allowed;
	}
}

.purple {
	padding: 24px 48px;
	font-weight: 400;
	font-size: 18px;
	color: $color-white;
	background-color: $color-purple-flash;

	&:hover {
		background-color: $color-purple-hover;
	}

	&:disabled {
		color: rgba(255, 255, 255, 0.6);
		background-color: $color-purple-soft;
	}
}

.ghost {
	padding: 24px 48px;
	font-weight: 400;
	font-size: 18px;
	color: $color-pink-flash;
	border: 1px solid $color-pink-flash;
	background-color: $color-white;

	&:hover {
		color: $color-pink-hover;
		border-color: $color-pink-hover;
	}

	&:disabled {
		color: $color-pink-soft;
		border-color: $color-pink-soft;
	}
}

.red {
	padding: 16px 32px;
	font-weight: 400;
	font-size: 18px;
	color: $color-white;
	background-color: $color-red-flash;

	&:hover {
		background-color: $color-red-hover;
	}

	&:disabled {
		color: rgba(255, 255, 255, 0.6);
		background-color: $color-red-soft;
	}
}

.orange {
	padding: 16px 32px;
	font-weight: 400;
	font-size: 18px;
	border-radius: 50px;
	background-color: $color-orange-soft;
	color: $color-black;

	&:hover {
		background-color: $color-orange-flash;
	}

	&:disabled {
		opacity: 0.4;
		color: rgba(0, 0, 0, 0.6);
		background-color: $color-orange-soft;
	}
}

@import "Themes/constants.scss";

.root {
	height: 83px;
	box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.07);

	.content {
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: var(--root-max-width);
		height: 100%;
		padding: 0 120px;

		@media screen and (max-width: $screen-m) {
			padding: 0 64px;
		}

		@media screen and (max-width: $screen-s) {
			padding: 0 16px;
		}
	}
}

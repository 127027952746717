@import "Themes/constants.scss";

.root {
	.title {
		font-weight: 500;
		font-size: 60px;
		color: $color-black;
	}

	.content {
		display: flex;
		justify-content: space-between;
		margin-top: 40px;

		@media screen and (max-width: $screen-m) {
			flex-direction: column;
		}

		.divider {
			width: 140px;

			@media screen and (max-width: $screen-m) {
				display: none;
			}
		}

		.form-container {
			max-width: 530px;
			width: 100%;

			.sub-title {
				margin-bottom: 40px;
				font-weight: 500;
				font-size: 30px;
				color: $color-black;
			}

			.text-field-container {
				margin-bottom: 32px;
			}

			.edit-form {
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 16px;
				letter-spacing: 0.5px;
				color: $color-black;

				:first-child {
					margin-left: 16px;
				}
			}
		}
	}
}

@import "Themes/constants.scss";

.root {
	display: flex;
	justify-content: space-between;
	min-height: 100vh;

	.left {
		padding: 72px 24px 0 120px;

		@media screen and (max-width: $screen-m) {
			padding-left: 24px;
		}

		@media screen and (max-width: $screen-s) {
			padding-left: 16px;
			padding-right: 16px;
		}

		.logo {
			width: 188px;
			height: 75px;
		}

		.static-logo {
			display: none;

			@media screen and (max-width: $screen-l) {
				z-index: -1;
				display: block;
				position: fixed;
				bottom: 0;
				right: 0;
			}

			@media screen and (max-width: $screen-s) {
				right: -98px;
			}
		}
	}

	.right {
		position: fixed;
		right: 0;
		max-width: 564px;
		width: 100%;
		height: 100%;
		background-color: $color-grey-soft;

		@media screen and (max-width: $screen-l) {
			max-width: 98px;
		}

		@media screen and (max-width: $screen-s) {
			display: none;
		}

		.animate-logo {
			position: fixed;
			width: 800px;
			height: 600px;
			right: -120px;
			top: -250px;

			@media screen and (max-width: $screen-l) {
				display: none;
			}
		}

		.animate-logo-2 {
			position: fixed;
			width: 800px;
			height: 600px;
			right: -255px;
			top: 409px;
			transform: rotate(15deg);

			@media screen and (max-width: $screen-l) {
				display: none;
			}
		}
	}
}

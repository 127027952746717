$screen-xl: 2559px;
$screen-l: 1440px;
$screen-m: 1023px;
$screen-s: 767px;
$screen-xs: 424px;

$custom-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

$color-green-flash: #12bf4d;
$color-blue-flash: #005176;
$color-turquoise-flash: #3fa79e;
$color-purple-flash: #320756;
$color-orange-flash: #ffb017;
$color-red-flash: #a63a23;
$color-pink-flash: #bd4b91;

$color-green-soft: #baf2cd;
$color-blue-soft: #a7c6d4;
$color-turquoise-soft: #c3eae6;
$color-purple-soft: #c5b2d4;
$color-orange-soft: #ffdc99;
$color-red-soft: #f08771;
$color-pink-soft: #f8b9df;

$color-purple-hover: #4e1480;
$color-pink-hover: #e34ba9;
$color-red-hover: #cc4c31;

$color-white: #ffffff;
$color-black: #000000;
$color-grey: #939393;
$color-grey-soft: #e7e7e7;
$color-black-transparent: rgba(0, 0, 0, 0.4);
$color-error: #da3d3d;

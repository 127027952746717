@import "Themes/constants.scss";

.root {
	@media screen and (max-width: $screen-m) {
		display: block;
	}

	display: none;

	.burger-menu-icon {
		cursor: pointer;
	}

	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.menu {
		position: absolute;
		width: max-content;
		min-width: 254px;
		z-index: 2;
		padding: 0 24px;
		top: 83px;
		right: 0;
		background-color: $color-white;
		box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.07);

		.separator {
			width: 100%;
			height: 1px;
			background-color: #e7e7e7;
		}

		.link {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 24px 0;
			font-weight: 400;
			font-size: 18px;
			color: $color-grey;
			transition: color 0.2s linear;
			cursor: pointer;

			svg {
				path {
					transition: stroke 0.2s linear;
				}
			}

			:last-child {
				margin-left: 12px;
			}

			&:hover {
				color: $color-black;

				svg {
					path {
						stroke: $color-black;
					}
				}
			}
		}
	}
}

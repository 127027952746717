@import "Themes/constants.scss";
@import "Themes/fonts.scss";
@import "Themes/modes.scss";
@import "Themes/variables.scss";

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-white);
	font-family: var(--font-primary);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

a {
	text-decoration: none;
	color: inherit;
}

.validation-error-message {
	font-weight: 400;
	font-size: 16px;
	color: $color-error;
	margin-top: 8px;
}

@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
	user-select: none;

	.checkbox {
		position: relative;
		appearance: none;
		margin: 0 16px 0 0;
		min-width: 16px;
		min-height: 16px;
		border: 1px solid $color-turquoise-flash;
		cursor: pointer;

		&:checked {
			background-color: $color-turquoise-flash;

			&::before,
			&::after {
				content: "";
				position: absolute;
				-webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
				transform: rotate(45deg);
				border-radius: 1px;
				background-color: $color-white;
			}

			&::before {
				width: 2px;
				height: 12px;
				left: 8px;
				top: 1px;
			}

			&::after {
				width: 6px;
				height: 2px;
				left: 0.5px;
				top: 8.5px;
			}
		}
	}
}

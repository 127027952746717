@import "Themes/constants.scss";

.root {
	.title {
		margin-top: 128px;
		margin-bottom: 40px;
		font-weight: 500;
		font-size: 30px;
	}

	.description {
		font-weight: 400;
		font-size: 16px;
		margin-bottom: 40px;
		letter-spacing: 0.005em;
		color: $color-black;
	}

	.form {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		gap: 24px;

		.fields {
			width: 100%;
		}

		.link_register {
			font-weight: 400;
			font-size: 16px;
			letter-spacing: 0.5px;
			text-decoration-line: underline;
			color: var(--color-button-secondary);
		}

		.button_container {
			margin-top: 16px;
		}
	}
}

@import "Themes/constants.scss";

:root {
	--root-max-width: 1440px;
	--root-margin: auto;
	--root-padding: 64px 120px;

	--font-primary: "Inter", sans-serif;
	--font-secondary: "Source Sans Pro", sans-serif;
}

@import "Themes/constants.scss";

.root {
	.title {
		font-weight: 500;
		font-size: 60px;
		padding-bottom: 40px;
		padding-top: 56px;
	}

	.description {
		font-weight: 400;
		font-size: 18px;
		padding-bottom: 40px;
	}

	.form {
		margin-bottom: 24px;
		display: flex;
		flex-direction: column;

		> * {
			margin-bottom: 24px;
		}

		h2 {
			font-weight: 600;
			font-size: 18px;
			letter-spacing: 0.5px;
		}

		.fields {
			width: 530px;

			@media screen and (max-width: $screen-s) {
				width: 100%;
			}
		}

		.email_container {
			position: relative;

			.tooltip {
				position: absolute;
				top: 25px;
				left: calc(530px + 14px);

				@media screen and (max-width: $screen-s) {
					left: calc(100% - 32px);
				}
			}
		}

		.password-restrictions {
			margin-top: calc(-24px + 8px);
			font-weight: 400;
			font-size: 14px;
			letter-spacing: 0.5px;
			color: $color-black-transparent;
		}

		.link_password {
			font-weight: 400;
			font-size: 16px;
			letter-spacing: 0.5px;
			text-decoration-line: underline;
		}

		.checkbox-label {
			letter-spacing: 0.5px;
			font-weight: 400;
			font-size: 18px;
			color: $color-black;
		}

		.underline-link {
			text-decoration: underline;
		}

		.button_container {
			display: flex;
			gap: 32px;
			flex-wrap: wrap;
			align-items: center;
		}

		.contact {
			font-weight: 400;
			font-size: 14px;
			letter-spacing: 0.5px;
			color: $color-black;
		}
	}
}

.divider {
	width: 100%;
	height: 1px;

	margin: 24px 0;

	background-color: var(--color-neutral-4);
}

@import "Themes/constants.scss";

.root {
	@media screen and (max-width: $screen-m) {
		display: none;
	}

	position: relative;
	display: flex;
	align-items: center;
	height: 100%;

	.link {
		position: relative;
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0 24px;
		font-weight: 600;
		font-size: 18px;
		letter-spacing: 0.5px;
		color: $color-grey;
		transition: color 0.2s linear;

		&:hover {
			color: $color-black;
		}
	}

	.active {
		color: $color-black;

		&::after {
			content: "";
			position: absolute;
			top: 81px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $color-black;
			animation: fadeIn 0.2s linear;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@import "Themes/constants.scss";

[theme-mode] {
	--color-neutral-0: #fdfdfd;
	--color-neutral-1: #f2f2f2;
	--color-neutral-2: #cdcdcd;
	--color-neutral-3: #b7b7b7;
	--color-neutral-4: #929292;
	--color-neutral-5: #727272;
	--color-neutral-6: #525252;
	--color-neutral-7: #323232;
	--color-neutral-8: #181818;

	--color-button-primary: #320756;
	--color-button-hover: #49097e;
	--color-button-active: #280544;
	--color-button-disabled: #c5b2d4;

	--color-button-secondary: #bd4b91;
}

[theme-mode="dark"] {
}
